import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "supporting-copy form-instructions-heading" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (!$setup.showUnlockedView)
    ? (_openBlock(), _createBlock($setup["resourceForm"], {
        key: 0,
        "form-settings-key": $props.formSettingsKey,
        "widget-data": $props.widgetData,
        documents: $setup.documents,
        "custom-data": $props.customData
      }, _createSlots({ _: 2 /* DYNAMIC */ }, [
        (!$setup.isNil($setup.customFormHeading) && $setup.customFormHeading != '')
          ? {
              name: "heading",
              fn: _withCtx(() => [
                _createElementVNode("h5", _hoisted_1, _toDisplayString($setup.customFormHeading), 1 /* TEXT */)
              ]),
              key: "0"
            }
          : undefined
      ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["form-settings-key", "widget-data", "documents", "custom-data"]))
    : ($setup.showUnlockedView)
      ? (_openBlock(), _createBlock($setup["ppcComplete"], {
          key: 1,
          document: $setup.documents[0]
        }, null, 8 /* PROPS */, ["document"]))
      : _createCommentVNode("v-if", true)
}